<template>
    <section class="container">
        <div class="bg-hero bg-orange-light-2-wave bg-no-repeat bg-cover px-5 xl:px-16 pt-8 lg:mb-24 rounded-tr-3xl rounded-tl-3xl lg:rounded-3xl">
          <div class="overflow-y-hidden relative grid gap-12 md:gap-24 lg:gap-16 lg:grid-cols-12 items-center">
            <div class="w-full row-col lg:py-[8rem] gap-6 lg:col-start-1 lg:col-end-8">
              <div class="row-col">
                <p class="font-bold text-[32px] lg:text-5xl font-heading text-header">Get started in Minutes</p>
                <p class="max-w-md text-[#88888A]">The most trustworthy cryptocurrency exchange platform available.</p>
              </div>
              <div class="flex flex-col gap-8 md:gap-0 md:flex-row max-w-[27rem] items-start md:items-center">
                <ul class="flex-1 row-col gap-5 characteristics">
                  <li class="flex gap-4 items-center font-semibold" v-for="(character, index) in characteristics" :key="index">
                    <IconCheck />
                    {{ character }}
                  </li>
                </ul>
                <div class="w-full md:w-auto">
                  <StoreButtonsContainer />
                  <div class="sm:max-w-xl mx-auto lg:flex lg:mx-0 h-full gap-x-5">
                    <div class="hidden relative md:flex">
                      <div class="relative">
                        <QrCode class="text-primary-1"/>
                      </div>
                      <div class="flex absolute left-[80%] -bottom-[5.5rem] items-end gap-x-2"
                           data-aos="fade-up-left">
                        <div class="pb-4 h-full">
                          <svg width="74" class="qr_code" height="57" viewBox="0 0 74 57" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M47.8475 54.3824C29.7133 47.9272 17.7463 29.8614 9.52634 11.1644C9.10378 10.2035 8.61719 9.2577 8.16443 8.33099C7.53945 10.6298 3.67023 29.3311 1.07792 29.0231C-0.437114 28.843 0.0996348 26.4297 0.30082 25.4607C1.75808 18.4547 3.24846 12.1196 5.17872 5.19079C6.63142 -0.02524 10.6164 0.0458523 14.5918 0.328829C20.661 0.760912 21.2113 1.23616 27.4631 1.95014C28.4935 2.06801 29.5634 2.09335 30.5356 2.33364C31.6788 2.61692 32.9863 3.54484 32.6017 5.09059C32.1659 6.84255 30.7576 7.14382 29.4917 6.92162C29.2274 6.87457 28.9604 6.83871 28.6921 6.81039C25.7448 6.50023 12.2493 4.18824 11.9607 5.33813C11.8631 5.72544 11.964 6.11271 12.0673 6.47269C15.0439 16.8597 20.5155 25.9757 26.6169 33.9794C30.7222 39.3655 35.6745 43.8632 41.2032 47.2577C45.6461 49.9858 50.5129 51.9787 55.6418 52.8315C61.1416 53.7465 66.22 52.77 71.8019 51.5078C75.0152 50.7808 72.9423 54.9589 71.2208 55.8689C69.3288 56.8698 67.1433 56.7212 65.1926 56.8344C59.0213 57.1889 53.2263 56.2968 47.8475 54.3824Z"
                                fill="#FB8E0B" />
                          </svg>
                        </div>
                        <p class="inline-flex text-body font-bold max-w-[6.25rem] font-heading">
                          Scan to Download Apex App
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div data-aos="fade-up"
                 data-aos-anchor-placement="bottom-bottom" class="lg:col-start-8 -mb-[35%] lg:mb-0 lg:absolute bottom-0 flex justify-center lg:col-end-[13] overflow-hidden">
              <img src="~/assets/images/cta/main.png" alt="cta image" class="" />
            </div>
          </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import IconCheck from "~/components/icons/IconCheck.vue";
import IconAppleLogo from "~/components/icons/IconAppleLogo.vue";
import IconGoogleStoreLogo from "~/components/icons/IconGoogleStoreLogo.vue";
import StoreButtonsContainer from "~/components/StoreButtonsContainer.vue";

const characteristics = ["Swift Transactions","Secured & Safe Payments","Instant Withdrawal"]
</script>