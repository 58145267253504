<template>
    <div class="bg-white">
      <div></div>
    </div>
</template>

<script setup lang="ts">

</script>

<style lang="scss" scoped>
.value-trade-slides {
 @apply min-h-[5rem];
}
.carousel__item {
    min-height: 200px;
    width: 100%;
    background-color: var(--vc-clr-primary);
    color: var(--vc-clr-white);
    font-size: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.symbol {
    @apply uppercase text-content-3 text-sm;
}

.trending {
    @apply text-sm pl-2;

    &.text-default {
        @apply text-green-500;
    }

    &.text-danger {
        @apply text-red-500;
    }
}

.single-value-trade-box {
    @apply select-none flex items-center gap-[.625rem];
}

.price {
    @apply text-content-3 font-semibold text-lg font-body;
}

.carousel__slide {
    padding: 10px;
}

.carousel__prev,
.carousel__next {
    box-sizing: content-box;
    border: 5px solid white;
}
</style>