<template>
    <div>
        <main>
            <HeroSection />
            <ServicesContainer />
            <CTA />
        </main>
    </div>
</template>

<script lang="ts" setup>

</script>
<style lang="scss" scoped>
</style>