<template>
  <section class="services__section">
    <div class="frame">
      <div class="services__head__container">
        <p class="head_text">We have more Solutions for you</p>
        <p class="body_text">We understand that your financial needs go beyond buying and selling crypto. Explore more
          possibilities here. Exchange your gift cards, pay utility bill, and swift airtime recharge.</p>
      </div>
      <div class="grid lg:grid-cols-12 gap-y-8 lg:gap-x-8">
        <div v-for="(service, index) in services" :key="index" :class="[service.classList, 'relative overflow-hidden pt-8 lg:pt-10 px-5 lg:px-10 rounded-3xl']">
          <div class="mb-8">
            <div class="font-heading text-lg lg:text-3xl font-bold text-title">{{service.title}}</div>
            <div class="text-description text-sm lg:text-lg">{{service.description}}</div>
          </div>
          <div class="flex justify-center relative bottom-0">
            <img :src="service.img" alt="" class="image" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>

import {onMounted} from "vue";
import {gsap} from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

onMounted(() => {
  createAnimation();
});

function createAnimation(){
  gsap.from('.image', {
    scrollTrigger: '.image',
    opacity: 0,
    y: 100,
    duration: 0.7,
    ease: ""
  })
}


const services = [
  {
    title: "Gift Cards",
    description: "Buy & Sell all forms of gift cards on the go and get your wallet funded in no time!",
    img: "/images/services/giftcard.png",
    animation: "load",
    // rivClass: "w-full md:w-[80%]",
    // component: defineAsyncComponent(() => import('@/components/icons/IconGiftcardLarge.vue')),
    classList: "bg-green-light col-span-12 lg:col-span-7 bg-green-light-wave",
  }, {
    title: "Trade Cryptocurrency",
    description: "We offer wide a variety of cryptocurrencies to choose from. Buy, Sell, & Swap with ease.",
    img: "/images/services/trade.png",
    animation: "smile",
    // rivClass: "w-[80%] md:w-full",
    // component: defineAsyncComponent(() => import('@/components/icons/IconCryptoLarge.vue')),
    classList: "bg-orange-light col-span-12 lg:col-span-5 bg-orange-light-wave",
  },
  {
    title: "Virtual Card",
    description: "Beat the stress of failed card transactions with Apex Virtual USD card. Make payments on your favourite platforms and enjoy a smooth transaction checkout.",
    img: "/images/services/virtualcard.png",
    animation: "card rotate",
    // rivClass: "w-full md:w-full",
    // component: defineAsyncComponent(() => import('@/components/icons/IconVirtualCardsLarge.vue')),
    classList: "bg-purple-light col-span-12 lg:col-span-5 bg-purple-light-wave",
  },
  {
    title: "Pay Utility Bills",
    description: "With just a few clicks, pay your cable bills, airtime and data subscription, all with cryptocurrencies or local currency/fiat.",
    img: "/images/services/payment.png",
    animation: "fall sequence",
    // rivClass: "w-full md:w-[70%]",
    // component: defineAsyncComponent(() => import('@/components/icons/IconPayBillsLarge.vue')),
    classList: "bg-sky-light col-span-12 lg:col-span-7 bg-blue-light-wave",
  }
]

// const animate = () => {
//   tl.from(CONTAINER.value.querySelector(".hero_text"), {
//     duration: .5,
//     y: "150",
//     opacity: 0,
//     stagger: .5,
//     ease: "sine",
//   })
//       .from(CONTAINER.value.querySelector(".hero_section p"), {
//         duration: .5,
//         x: "100",
//         opacity: 0,
//         stagger: 0.1,
//         ease: "back",
//       })
//       .from(CONTAINER.value.querySelector(".store_section"), {
//         duration: .5,
//         y: "100",
//         opacity: 0,
//         stagger: 0.1,
//         ease: "back",
//       })
//       .from(CONTAINER.value.querySelector(".hero_images"), {
//         duration: .5,
//         scale: "0",
//         opacity: 0,
//         stagger: 0.1,
//         ease: "back",
//       });
//   tl.play();
// };
//
// onMounted(() => {
//   animate();
//
// });
// onBeforeUnmount(() => {
//   console.log(tl.kill());
//
// });
</script>

<style lang="scss" scoped>
.services__section {
  .frame {
    @apply row-col gap-[3.125rem] container overflow-x-hidden py-[4.375rem] md:py-[7.5rem];
    //  px-[1.25rem]  md:px-[6.25rem];
    .services {
      &__head__container {
        @apply text-center container max-w-[53.625rem] px-0 row-col gap-[2.5rem] md:gap-[1.875rem] md:mb-[1.875rem];

        .head_text {
          @apply text-2xl md:text-[2rem] font-bold  text-header font-heading;
        }

        .body_text {
          @apply text-body text-[0.938rem] leading-[1.5rem] font-normal;
        }
      }
    }
  }
}
</style>